.login__form-head{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.sign-up__logo-cont{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.sign-up__logo{
    margin-bottom: 8px;
}
.sign-up__logo-role{
    color:var(--text-color-dark);
    font-family: "PoppinsSemiBoldItalic";
    font-size:20px;
    margin: 0;
}
.login__return-para{
    position: absolute;
    color:var(--text-color-dark);
    font-size:18px;
    font-family:'PoppinsMedium';
    transition:var(--transition);
    display: flex;
    align-items: center;
}
.login__return-para:hover{
    color:var(--main-color);
}
.login__return-icon{
    margin-right: 5px;
    width: 20px;
    height: 20px;
}
.login__logo{
    margin: auto;
    width: 70px;
    height: 70px;
}
.login-form-content{
    width:50%;
    margin: auto;
    background-color:white;
    box-shadow: 10px 10px 35px #4807E61A;
    padding: 30px 0;
    margin-bottom: 10px;
    border-radius: 5px;
}
.login__form-content-container{
    width:80%;
    margin: auto;

}
.login-form-title{
    font-size: 26px;
    font-family: 'PoppinsSemiBold';
    color: var(--text-color-dark);
    margin-bottom: 10px;
    text-align: center;
}
.form__input-cont{
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}
.form__input-label{
    color:var(--main-color);
    font-family:'PoppinsMedium';
    font-size: 16px;
    margin-bottom: 8px;
}
.form__input{
    border: 1px solid #BFBFBF;
    height: 60px;
    border-radius: 4px;
    padding: 20px;
    outline: #BFBFBF;
    transition:var(--transition);
    width:100%;
}
.form__input:focus ,.form__input:focus-visible{
    border:1px solid var(--main-color);
}
.form__input-password{
    position: relative;
}
.form__view-pass{
    --size:20px;
    position: absolute;
    width: var(--size);
    height: var(--size);
    top:calc(50% - var(--size)/2);
    right: 30px;
    z-index: 100;
    cursor: pointer;
    background-color:transparent;
    border:0;
    line-height: 0;
}
.form__view-pass-icon{
    width: var(--size);
    height: var(--size);
}
.form__view-pass-icon path{
    transition: var(--transition);
}
.toggle-view-pass{
    transform: translate(-22px, -22px);
    transition: var(--transition);
}
.login__remeber-me{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.login__remeber-me-cont{
    display: flex;
    align-items: center;
}
.login__remeber-me-check{
    border-radius: 2px;
    width: 15px;
    height: 15px;
    margin-right: 8px;
    accent-color: var(--main-color);
}
.login__remeber-me-label{
    font-family: 'PoppinsMedium';
    color:var(--text-color-dark);
    margin: 0;
}
.login__forget-password{
    margin-left: auto;
    font-size: 16px;
    font-family: 'PoppinsMedium';
    color: var(--main-color);
    transition: var(--main-color);
}
.login__forget-password:hover{
    color: var(--main-color);
    opacity: .8
}
.form__submit{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 48px;
    border-radius: 4px;
    font-size: 20px;
    font-family: 'PoppinsMedium';
    color: white;
    background-color: var(--main-color);
    margin: auto;
    margin-bottom: 25px;
}
.login__with-other-ways{
    position: relative;
    font-size: 18px;
    color: var(--main-color);
    font-family: 'PoppinsMedium';
    text-align: center;
    margin-top: 30px;
}
.login__with-other-ways::before ,.login__with-other-ways::after{
    content:'';
    position:absolute;
    background-color: #BFBFBF;
    height:1px;
    width:35%;
    top:calc(50% - 1px/2);
}
.login__with-other-ways::before{
    left: 0;
} 
.login__with-other-ways::after{
    right: 0;
}
.login__social-media-cont{
    display: flex;
    align-items:center;
    justify-content:center;
}
.login__social-media-button{
    display: flex;
    align-items:center;
    justify-content: center;
    background-color:transparent;
    border:0;
    outline:0;
    cursor:pointer;
    margin-right: 30px;
}
.login__social-media-button:last-of-type{
    margin-right:0;
}
.login__dont-have-accout{
    text-align: center;
    color: #8B8B8B;
    font-size: 22px;
    font-family: 'poppinsMedium';
}
.login__move-to-register{
    color: var(--main-color);
    font-size: 24px;
    font-family:'PoppinsSemiBold';
    transition: var(--transition);
}
.sign-up__move-to-login:hover{
    color:var(--main-color);
    text-decoration: underline;
}
.modal__form-input-error-message{
    color: red;
    font-size: 14px;
    margin-top: 4px;
}
.modal__form-input--error{
    border-color:red!important
}
.modal__form-input--error::placeholder{
    color: red;
    font-weight: 0;
    font-size:14px
}
@media(max-width:1024px){
    .login-form-content{
        width:80%
    }
    .login__form-content-container{
        width:80%
    }
}
@media(max-width:768px){
    .login-form-content{
        width:90%
    }
    .login__form-content-container{
        width:90%
    }
    .login__with-other-ways::before, .login__with-other-ways::after{
        width: 30%  ;
    }
    #login{
        padding: 30px 5px;
    }
}
@media(max-width:426px){
    .login__form-content-container{
        width:95%
    }
    .login__remeber-me{
        flex-direction: column;
        align-items: flex-start;
    }
    .login__forget-password{
        margin-left:0
    }
    .form__submit{
        margin-bottom: 30px;
        height: 50px;
    }
    .login__with-other-ways{
        font-size: 16px;
        margin-top: 40px;
    }
    .login__remeber-me{
        margin-bottom: 20px;
    }
    .login__remeber-me-cont{
        margin-bottom: 10px;
    }
    .login__remeber-me-check{
        width: 20px;
        height: 20px;
        margin-right: 6px;
    }
    .login__alredy-have-accout{
        font-size: 14px;
    }
    .login__move-to-register{
        font-size: 16px;
    }
    .login-form-content{
        padding: 20px 10px;
        margin-bottom: 5px;
    }
    #login {
        padding: 10px 0px;
    }
    .login-form-title {
        font-size: 22px;
    }
    .form__input-label{
        margin-bottom: 5px;
    }
    .form__input {
        padding: 15px 20px;
    }
    .login__return-span{
        display:none
    }
    .login__return-icon {
        width: 40px;
        height: 40px;
        color: var(--main-color);
        margin-left: 10px;
    }
    .form__input-cont{
        margin-bottom: 15px;
    }
    .login__social-media-button{
        margin-right: 0px;
    }
    .login__logo{
        width:70px;
        height:70px;
    }
}