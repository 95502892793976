
.page-header{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}
.page-header__text{
    font-size: 14px;
    font-weight: 600;
    color:var(--text-color-dark);
}