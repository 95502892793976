.table-cont{
    background-color: white;
    padding: 20px;
    /* border-radius: 20px; */
    /* box-shadow: 0 0px 20px 0px rgb(0 0 0 / 30%); */
}
.table__title{
    font-size: 23px;
    font-family: PoppinsBold;
    color:var(--text-color-dark);
    margin-bottom: 0;
}
.table__header-wrapper{
    display: flex;
    margin-bottom: 25px;
    align-items: center;
    gap: 10px;
}

.table__add-button{
    margin-left: auto;    
    color:var(--text-color-dark);
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.modal__form-input-wrapper{
    display: flex;
    flex-direction: column;
}
.modal__form-input-label{
    color:var(--text-color-dark);
    font-family: 'PoppinsMedium';
    margin-bottom: 8px;
}
.modal__form-input{
    border: 1px solid #BFBFBF;
    height: 40px;
    border-radius: 4px;
    padding: 20px;
    outline: #BFBFBF;
}
.modal__form-input--error{
    border-color:red
}
.modal__form-input--error::placeholder{
    color: red;
    font-weight: 0;
    font-size:14px
}
.modal__form-input-required{
    color: red;
}

.modal__form-input-error-message{
    color: red;
    font-size: 14px;
    margin-top: 4px;
}
@media (max-width:500px) {
    .table__header-wrapper{
        flex-direction: column;
    }   
}